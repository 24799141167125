import React from "react";

import { Modal as AntdModal, Drawer } from "antd";
import GlobalStoreContext from "../../GlobalStoreContext";

interface ModalProps {
    open?: boolean;
    title?: React.ReactNode;
    okText?: string;
    onOk?: () => void;
    onClose?: () => void;
    onCancel?: () => void;
    children: any;
    width?: number;
    destroyOnClose?: boolean;
    style?: any;
    footer?: boolean | React.ReactNode;
    closable?: boolean;
    className?: string;
}

const Modal: React.FC<ModalProps> = ({
    open,
    title,
    okText,
    onOk,
    onClose,
    onCancel,
    children,
    width = 520,
    destroyOnClose,
    style,
    footer,
    closable,
    className,
}) => {
    const globalStoreContext = React.useContext(GlobalStoreContext);

    const renderFooter = (): React.ReactNode | undefined => {
        if (footer === true) {
            return (
                <div className="flex flex-row justify-end items-center gap-4">
                    <button className="plot-button alternate-button" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="plot-button primary-button" onClick={onOk}>
                        {okText || "Ok"}
                    </button>
                </div>
            );
        } else if (footer) {
            return footer;
        } else {
            return null;
        }
    };

    return globalStoreContext.windowSize.width < 992 ? (
        <Drawer open={open} placement="bottom" title={title} onClose={onClose} extra={renderFooter()} height={"90%"}>
            {children}
        </Drawer>
    ) : (
        <>
            <AntdModal
                title={title}
                open={open}
                onOk={onOk}
                onCancel={onCancel}
                okText={okText || "Ok"}
                footer={renderFooter()}
                width={width}
                destroyOnClose={destroyOnClose || true}
                style={style}
                closable={closable}
                className={className}
            >
                {children}
            </AntdModal>
        </>
    );
};

export default Modal;
